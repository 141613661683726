<template>
  <div>
    <v-dialog
      persistent
      scrollable
      v-model="dialog.event"
      :min-height="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? '' : 550"
      width="450">
      <v-card v-if="Object.keys(selected.event).length > 0"
        width="450"
        style="border-radius: 15px;"
        flat>
        <v-toolbar
        :class="'elevation-0'">
          <v-toolbar-title class="body-1 font-weight-bold text-capitalize">
            {{ selected.event.title }}
          </v-toolbar-title>

          <v-spacer/>

          <v-btn icon
            @click="dialog.event = false; selected.event = {};"
            color="red">
              <v-icon size="25" class="color-default">mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider
          style="border-top: 2px dashed #e0e0e0;"></v-divider>

        <!-- <v-card-text class="body-1 text-capitalize font-weight-bold pb-0">
          {{ selected.event.title }}
        </v-card-text> -->

        <v-card-text class="body-2 font-weight-light text-first mt-4">
          <p class="mb-2">
            <v-icon small class="mr-1">mdi-calendar-month</v-icon>
            <span v-if="selected.event.start_date === selected.event.end_date">
              Tanggal <b>{{ selected.event.start_date | date }}</b>
            </span>
            <span v-if="selected.event.start_date !== selected.event._date">
              Tanggal <b>{{ selected.event.start_date | date }}</b> s/d <b>{{ selected.event._date | date }}</b>
            </span>
          </p>

          <p class="mb-2">
            <v-icon small class="mr-1">mdi-clock-time-four-outline</v-icon>
            Pukul <b>{{ selected.event.start_time  }}</b> s/d <b>{{ selected.event.end_time }}</b>
          </p>

          <p>
            <v-icon small class="mr-1">mdi-map</v-icon>
            Lokasi <b>{{ selected.event.location !== '' ? selected.event.location : '-' }}</b>
          </p>

          <p>
            <span v-html="selected.event.desc"></span>
          </p>

          <div v-if="selected.event.image_url !== ''" class="mt-4">
            <!-- <v-avatar
              size="100%"
              tile
            > -->
              <v-img :src="selected.event.image_url" contain></v-img>
            <!-- </v-avatar> -->
          </div>
        </v-card-text>
      </v-card>
		</v-dialog>
    <v-layout wrap>
      <v-flex>
        <v-card
          v-if="process.run"
          flat
          height="250"
          width="100vw"
          color="#00000040">
          <v-skeleton-loader
            :loading="process.run"
            transition="scale-transition"
            :tile="false"
            class="ma-auto load_card_x_large"
            type="image"
            style="width: 100% !important; height: 100% !important; min-height: 100% !important;">
          </v-skeleton-loader>
        </v-card>
        <div v-if="!process.run">
          <!-- <v-col
            cols="12" class="pt-0"> -->
            <v-card
              flat
              cover
               width="100%" height="100" class="img_center align-center pa-3"
              :style="`background-color: ${set_color}`">
              <!-- <v-img :src="require('@/assets/image/Candi-Prambanan.jpg')" width="100%" height="100" style="" :gradient="`to right, ${set_color_rgb_up}, #000000a6`" class="img_center align-center"> -->
                <!-- <v-card>
                  <v-card-title
                    class="text-h4 white--text justify-center mb-2 pt-6"
                    style="font-weight: 500;">
                    {{ menu[selected.menu].text }}
                  </v-card-title>

                  <v-card-subtitle
                    class="body-1 white--text text-center mb-4"
                    style="opacity: 50%; font-weight: 500;">
                    Mengenal {{ menu[selected.menu].text }} di kabupaten Klaten.
                  </v-card-subtitle>
                </v-card> -->

                <div class="d-flex align-center justify-center font-weight-black">
                  <div class="ml-auto" style="width: 300px;">

                  </div>
                  <div class="d-flex justify-center" style="width: 100%;">
                    <div class="mr-3">
                      <v-img
                        contain
                        width="60"
                        height="auto"
                        alt="Kabupaten Klaten"
                        :src="require('@/assets/logo/favicon-logo.png')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              size="20"
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </div>
                    <div class="text-center">
                      <div
                        class="fs-28 font-weight-bold white--text justify-center mb-2"
                        style="font-weight: 500;">
                        Informasi Agenda kabupaten Klaten
                      </div>

                      <div
                        class="body-1 white--text text-center font-weight-bold"
                        style="opacity: 100%; font-weight: 500;">
                        Mengenal Agenda di kabupaten Klaten.
                      </div>
                    </div>
                  </div>
                  <div class="ml-auto" style="width: 300px;">
                    <div class="white--text text-center">
                      <div v-if="clock.time.length > 0">
                        <div
                          class="fs-18 font-weight-bold"
                          style="opacity: 100%;">
                          {{ clock.date | date }}
                        </div>

                        <div
                          class="fs-25 font-weight-bold">
                          {{ clock.time }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              <!-- </v-img> -->
            </v-card>
          <!-- </v-col> -->
        </div>

        <section v-if="!process.run">

          <!-- <div>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  elevation="0"
                  outlined
                  dark
                  min-width="30"
                  small
                  min-height="30"
                  color="#7B7B7B"
                  class="text-capitalize px-0"
                  style="border-radius: 6px;"
                  v-bind="attrs"
                  v-on="on"
                  @click="launchIntoFullscreen();">
                  <v-icon size="18">mdi-fullscreen</v-icon>
                </v-btn>
              </template>

              <span>
                Fullscreen
              </span>
            </v-tooltip>
          </div> -->
          <div>
            <v-card flat>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="6">
                    <div class="red--text lighten-1--text font-weight-medium fs-21 pb-2" style="opacity: 70%;">
                      Agenda Hari Ini {{ new Date() | weekday }}, {{ new Date() | day }} {{ new Date() | month }} {{ new Date() | year }}
                    </div>
                    <v-divider class="my-2" style="border-color: #fa7f75;"></v-divider>
                    <v-card-text
                      v-if="Object.keys(list_now).length < 1"
                      class="pb-0 my-4" style="height: 200px; display: flex; align-items: center; justify-content: center;">
                      <div class="text-center">
                        <v-img
                          class="mx-auto"
                          contain
                          alt="vuetify"
                          :width="90"
                          :height="90"
                          :src="require('@/assets/image/empty.png')">
                          <!-- <template v-slot:placeholder>
                            <v-row
                              class=" "
                              align="center"
                              justify="center">
                              <v-progress-circular
                                indeterminate
                                :color="set_color">
                              </v-progress-circular>
                            </v-row>
                          </template> -->
                        </v-img>
                        <div class="mt-3 fs-16">
                          Belum Ada Data
                        </div>
                      </div>
                    </v-card-text>
                    <div v-if="Object.keys(list_now).length > 0">
                      <div :class="list_date_now.length > 4 ? 'scroll-blk-now' : 'scroll-blk-now-none'" class="">
                        <div :class="list_date_now.length > 4 ? 'scroll-text-now' : 'scroll-text-now-none'" id="data-now">

                          <div v-for="(item, indexs) in list_now" :key="indexs">
                            <div v-for="(i, index) in item" :key="index">
                              <div class="d-flex align-start py-2" style="height: 135px; min-height: 135px; max-height: 135px;">
                                <div class="text-left fs-16 font-weight-medium" style="width: 140px; min-width: 140px; max-width: 140px;">
                                  <span>{{ new Date(`${i.start_date} ${i.start_time}`) | time }}</span> s/d <span>{{ new Date(`${i.end_date} ${i.end_time}`) | time }}</span>
                                </div>
                                <div>
                                  <div class="fs-18 font-weight-medium mb-2">
                                    {{ i.title }}
                                  </div>
                                  <div class="d-flex align-center" style="flex-wrap: wrap;">
                                    <div class="d-flex align-center pr-2">
                                      <v-icon small class="mr-1">mdi-map-marker</v-icon>
                                      <div>
                                        {{ i.location === '' ? '-' : i.location }},
                                      </div>
                                    </div>
                                    <div class="d-flex align-center">
                                      <v-icon small class="mr-1">mdi-account-multiple</v-icon>
                                      <div>
                                        {{ i.dihadiri === '' ? '-' : i.dihadiri }}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <v-divider class="my-2" v-if="(item.length - 1) > index"></v-divider>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" md="6">
                    <div class="primary--text font-weight-medium fs-21 pb-2" style="opacity: 70%;">
                      Agenda Akan Datang
                    </div>
                    <v-divider class="my-2" style="border-color: #669edf;"></v-divider>
                    <v-card-text
                      v-if="Object.keys(list).length < 1"
                      class="pb-0 my-4" style="height: 200px; display: flex; align-items: center; justify-content: center;">
                      <div class="text-center">
                        <v-img
                          class="mx-auto"
                          contain
                          alt="vuetify"
                          :width="90"
                          :height="90"
                          :src="require('@/assets/image/empty.png')">
                          <!-- <template v-slot:placeholder>
                            <v-row
                              class=" "
                              align="center"
                              justify="center">
                              <v-progress-circular
                                indeterminate
                                :color="set_color">
                              </v-progress-circular>
                            </v-row>
                          </template> -->
                        </v-img>
                        <div class="mt-3 fs-16">
                          Belum Ada Data
                        </div>
                      </div>
                    </v-card-text>
                    <div v-if="Object.keys(list).length > 0">
                      <div :class="list_date_now.length > 4 ? 'scroll-blk-now' : 'scroll-blk-now-none'" class="">
                        <div :class="list_date_now.length > 4 ? 'scroll-text-now' : 'scroll-text-now-none'" id="data">

                          <div v-for="(item, indexs) in list" :key="indexs">
                            <div v-for="(i, index) in item" :key="index">
                              <div class="d-flex align-start py-2" style="height: 135px; min-height: 135px; max-height: 135px;">
                                <!-- <div class="text-left fs-16 font-weight-medium" style="width: 140px; min-width: 140px; max-width: 140px;">

                                  <span>{{ new Date(`${i.start_date} ${i.start_time}`) | time }}</span> s/d <span>{{ new Date(`${i.end_date} ${i.end_time}`) | time }}</span>
                                </div> -->
                                <div>
                                  <div class="pb-1">
                                    <div v-if="i.start_date === i.end_date">
                                      {{ i.start_date | date }}, <span>{{ new Date(`${i.start_date} ${i.start_time}`) | time }}</span> s/d <span>{{ new Date(`${i.end_date} ${i.end_time}`) | time }}</span>
                                    </div>
                                    <div v-else>
                                      <div>
                                        {{ i.start_date | date }} - {{ i.end_date | date }}, <span>{{ new Date(`${i.start_date} ${i.start_time}`) | time }}</span> s/d <span>{{ new Date(`${i.end_date} ${i.end_time}`) | time }}</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="fs-18 font-weight-medium mb-2">
                                    {{ i.title }}
                                  </div>
                                  <div class="d-flex align-center" style="flex-wrap: wrap;">
                                    <div class="d-flex align-center pr-2">
                                      <v-icon small class="mr-1">mdi-map-marker</v-icon>
                                      <div>
                                        {{ i.location === '' ? '-' : i.location }},
                                      </div>
                                    </div>
                                    <div class="d-flex align-center">
                                      <v-icon small class="mr-1">mdi-account-multiple</v-icon>
                                      <div>
                                        {{ i.dihadiri === '' ? '-' : i.dihadiri }}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <v-divider class="my-2" v-if="(item.length - 1) > index"></v-divider>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </div>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import moment from 'moment';
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        clock: {
          date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
          time: ''
        },
        dialog: {
          event: false
        },
        test: '',
        menu: [
          {
            label: 'Bulan',
            value: 'month'
          },
          {
            label: 'Minggu',
            value: 'week'
          },
          {
            label: 'Hari',
            value: 'day'
          },
          {
            label: '4 Hari',
            value: '4day'
          }
        ],
        category: ['Semua', 'Kelas', 'Praktek'],
        filter: {
          title: '',
          category: 0,
          calendar: true,
          label: 'Bulan',
          type: 'month'
        },
        picker: {
          date: false
        },
        date: {
          min: moment().format('Y-MM-DD'),
          now: moment().format('Y-MM-DD')
        },
        selected: {
          open: false,
          element: null,
          event: {}
        },
        list_date_now: [],
        colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
        list: {},
        list_now: {},
        pagination: {},
        process: {
          run: false
        },
        // SEO
        content: {
          url: '/agenda',
          title: `Agenda - Kabupaten Klaten`,
            description: `Agenda Kabupaten Klaten`,
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      Pagination: () => import('@/components/Pagination')
    },
    watch: {
      // 'list': function() {

      //   this.launchIntoFullscreen()
      // }
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      fcm () {
        return this.$store.state.member.auth.fcm
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        console.log(this.$store.state.header.config.color)
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      },
      set_color_rgb_down () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.25)`
      }
    },
    mounted () {
      setInterval(this.updateTime, 1000)
      this.fetch()
		// setTimeout('pageScroll()', 1200);

    },
    methods: {
      pageScroll() {
        var my_time;
		var count = 0;
        // If condition to set repeat
        if (count < 2) {
          var objDiv = document.getElementById("data");
          objDiv.scrollTop = objDiv.scrollTop + 1;
          if (objDiv.scrollTop == (objDiv.scrollHeight - (this.list_date_now.length > 4 ? 200 : 400))) {
            setTimeout(function() {
              objDiv.scrollTop = 0;
              count++;
              }, 1200);
          }
          //set scrolling time start
          setTimeout(() => {
            this.pageScroll()
          }, 20)
          //set scrolling time end
        }
      },
      pageScrollNow() {
        var my_time;
		var count = 0;
        // If condition to set repeat
        if (count < 2) {
          var objDiv = document.getElementById("data-now");
          objDiv.scrollTop = objDiv.scrollTop + 1;
          if (objDiv.scrollTop == (objDiv.scrollHeight - (Math.round(window.innerHeight - 210)))) {
            setTimeout(function() {
              objDiv.scrollTop = 0;
              count++;
              }, 1200);
          }
          //set scrolling time start
          setTimeout(() => {
            this.pageScrollNow()
          }, 20)
          //set scrolling time end
        }
      },

      async launchIntoFullscreen() {
        let elem = document.documentElement
        if(elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if(elem.mozRequestFullScreen) {
          elem.mozRequestFullScreen();
        } else if(elem.webkitRequestFullscreen) {
          elem.webkitRequestFullscreen();
        } else if(elem.msRequestFullscreen) {
          elem.msRequestFullscreen();
        }
      },

// Launch fullscreen for browsers that support it!
      updateTime () {
        let cd = new Date()

        this.clock.time = this.zeroPadding(cd.getHours(), 2) + ':' + this.zeroPadding(cd.getMinutes(), 2) + ':' + this.zeroPadding(cd.getSeconds(), 2)
      },
      zeroPadding (num, digit) {
        let zero = ''

        for(let i = 0; i < digit; i++) {
          zero += '0'
        }
        return (zero + num).slice(-digit)
      },
      async fetch(pagination = { row: 20, page: 1 }) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })

        this.process.run = true

        let params = {
          limit: 100000
          // filter: [
          //   {
          //     type: 'string',
          //     field: 'agenda_name',
          //     value: this.filter.title,
          //     comparison: '<>'
          //   },
          //   {
          //     type: 'string',
          //     field: 'agenda_category_name',
          //     value: this.filter.category === 0 ? '' : this.filter.category === 1 ? 'Kelas' : 'Praktek'
          //   }
          // ]
        }

        this.list_now = []

        this.list = []

        await this.$axios.$get(`${process.env.API}sites/event/data`, { params })
        .then((response) => {
          let res = response

          if (res.status === 200) {
            this.process.run = false

            // this.list = []

            let date_now = res.results.data.filter(({ start_date }) => start_date === new Date().toISOString().split('T')[0])
            let date_next = res.results.data.filter(({ start_date }) => start_date > new Date().toISOString().split('T')[0])
            this.list_date_now = date_now
            const result = Object.groupBy(date_next, ({ start_date }) => start_date);
            this.list_now = Object.groupBy(date_now, ({ start_date }) => start_date);
            this.list = (res.results.data).length > 0 ? result : {}

            // this.launchIntoFullscreen(document.documentElement);
            console.log(document.documentElement)
            setTimeout(() => {
              this.pageScroll()
            }, 1000)
            setTimeout(() => {
              this.pageScrollNow()
            }, 1000)

            console.log(Math.round(window.innerHeight - 210))
            console.log(window.innerHeight)

            // function toggleFullscreen() {
              // let elem = document.documentElement

              // if (elem.requestFullscreen) {
              //   elem.requestFullscreen();
              // } else if (elem.webkitRequestFullscreen) { /* Safari */
              //   elem.webkitRequestFullscreen();
              // } else if (elem.msRequestFullscreen) { /* IE11 */
              //   elem.msRequestFullscreen();
              // }
            // }
          }
        })
      },
      viewDay ({ date }) {
        this.focus = date
        this.filter.type = 'day'
      },
      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },
      showEvent ({ nativeEvent, event }) {
        const open = () => {
          this.selected.event = event
          this.selected.element = nativeEvent.target
          setTimeout(() => {
            this.selected.open = true
          }, 10)
        }

        if (this.selected.open) {
          this.selected.open = false
          setTimeout(open, 10)
        } else {
          open()
        }

        nativeEvent.stopPropagation()
      }
    }
  }
</script>

<style scoped>
.search-style.v-text-field--outlined >>> fieldset {
  border-color: #dadada;
}
.scroll-blk{
		/* background-color: #b5141c; */
		height: 400px;
		width:100%;
		/* padding: 8px 8px 0px; */
		position: relative;
		margin:15px auto;
		display: inline-block;
		box-sizing: border-box;
	}
	.scroll-text{
		overflow-y: auto;
		height: 400px;
		overflow-x: hidden;
		background-color: #fff;
		text-align: left;
		/* padding: 5px; */
		box-sizing: border-box;
		/* font-family: 'MyriadPro-Regular'; */
	}

  .scroll-blk-200{
		/* background-color: #b5141c; */
		height: 250px;
		width:100%;
		/* padding: 8px 8px 0px; */
		position: relative;
		margin:15px auto;
		display: inline-block;
		box-sizing: border-box;
	}
	.scroll-text-200{
		overflow-y: auto;
		height: 250px;
		overflow-x: hidden;
		background-color: #fff;
		text-align: left;
		/* padding: 5px; */
		box-sizing: border-box;
		/* font-family: 'MyriadPro-Regular'; */
	}

  .scroll-blk-now-none{
		/* background-color: #b5141c; */
		max-height: calc(100vh - 210px);
    height: calc(100vh - 210px);
		width:100%;
		/* padding: 8px 8px 0px; */
		position: relative;
		/* margin:15px auto; */
		display: inline-block;
		box-sizing: border-box;
	}
	.scroll-text-now-none{
		overflow-y: auto;
		max-height: calc(100vh - 210px);
    height: calc(100vh - 210px);
		overflow-x: hidden;
		background-color: #fff;
		text-align: left;
		/* padding: 5px; */
		box-sizing: border-box;
		/* font-family: 'MyriadPro-Regular'; */
	}

  .scroll-blk-now{
		/* background-color: #b5141c; */
		height: calc(100vh - 210px);
		width:100%;
		/* padding: 8px 8px 0px; */
		position: relative;
		/* margin:15px auto; */
		display: inline-block;
		box-sizing: border-box;
	}
	.scroll-text-now{
		overflow-y: auto;
		height: calc(100vh - 210px);
		overflow-x: hidden;
		background-color: #fff;
		text-align: left;
		/* padding: 5px; */
		box-sizing: border-box;
		/* font-family: 'MyriadPro-Regular'; */
	}
</style>
