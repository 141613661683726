<template>
  <div
    class="fill-height">
    <!-- style="padding-bottom: 900px; padding-top: 128px;" -->
    <v-layout wrap>
      <v-flex>
        <section>
          <div class="font-weight-medium mb-2 mt-2" :style="`color: ${set_color}`" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'text-h5' : 'body-1'">
            Visi & Misi
          </div>
          <!-- <v-container>
            <v-row>
              <v-col
                cols="12">
                <v-card
                  flat
                  class="background-default">
                  <v-card-title
                    class="text-h4 white--text justify-center mb-2 pt-6"
                    style="font-weight: 500;">
                    VISI dan MISI
                  </v-card-title>

                  <v-card-subtitle
                    class="body-1 white--text text-center mb-4"
                    style="opacity: 50%; font-weight: 500;">
                    Mengenal VISI dan MISI Kabupaten Klaten.
                  </v-card-subtitle>

                </v-card>
              </v-col>
            </v-row>

          </v-container> -->
          <v-row>
            <v-col
              cols="12" class="py-0">
              <v-card
                flat>

                <v-card-text
                  class="body-1 line-text-second mb-4">
                  <div class="text-h5 font-weight-medium border-bottom-default" :style="`color: ${set_color}`">
                    <span>VISI</span>
                  </div>

                  <div
                    class="mb-6 mt-4">
                    <div class="font-weight-medium title black--text">
                        Mewujudkan Klaten Yang Maju Sejahtera Berkelanjutan
                    </div>

                    <div class="mt-2 black--text">
                      <ol>
                        <li>MAJU mengarah pada sumber daya manusia yang berkualitas. Dengan SDM yang unggul, produktif dan berbudaya, akan mengakselerasi pembangunan Klaten yang berdaya saing.</li>
                        <li>SEJAHTERA yang juga bisa dimaknai adil makmur sebagaimana menjadi cita-cita nasional yang tertuang dalam Pembukaan UUD 1945 dapat dimaknai masyarakat mampu memenuhi kebutuhan tanpa kekurangan, baik secara material maupun spiritual.</li>
                        <li>BERKELANJUTAN berorientasi pada upaya pembangunan berkelanjutan dalam artian pembangunan yang mendasarkan aspek yang menyeluruh baik prinsip kesejahteraan ekonomi, keadilan sosial, dan pelestarian lingkungan.</li>
                      </ol>
                    </div>
                  </div>

                  <div class="text-h5 font-weight-medium border-bottom-default" :style="`color: ${set_color}`">
                    <span>MISI</span>
                  </div>

                  <div
                    class="mb-6 mt-4">
                    <div class="black--text">
                      <ol>
                        <li>Membangun manusia yang produktif, berkualitas dan berkepribadian.</li>
                        <li>Efisiensi pelayanan dan memastikan akses kesehatan untuk rakyat guna menciptakan manusia Indonesia yang sehat jasmani dan rohani.</li>
                        <li>Naik kelasnya ekonomi rakyat serta penguatan ekonomi yang inklusif dan kreatif yang mendukung investasi daerah dalam rangka mewujudkan keadilan sosial.</li>
                        <li>Akuntabilitas tata pemerintahan daerah tabilitas tata pemerintahan daerah yang bersih bebas dari korupsi dan berkeadaban serta setia pada Amanat Penderitaan Rakyat (Ampera), Pancasila, UUD 1945, BersamaKita menjunjung tinggi hukum demi menjamin hak-hak rakyat.</li>
                        <li>
                          Nilai Daya Saing dan Kemandirian Daerah berbasis potensi sumber daya lokal yang didukung Pembangunan Infrastruktur yang Berkualitas dan Merata serta Pengembangan Klaten sebagai Kabupaten Cerdas (smartcity)
                        </li>
                        <li>
                          Gotong royong memajukan kebudayaan dalam semangat kebhinekaan dan toleransi serta menjaga kelestarian lingkungan hidup warisan leluhur bangsa Indonesia.
                        </li>
                      </ol>
                    </div>
                  </div>

                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        process: {
          load: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/profil?menu=visi-misi',
          title: 'Visi & Misi',
          description: 'Visi & Misi Klaten',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {

    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      fcm () {
        return this.$store.state.member.auth.fcm
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      },
      set_color_rgb_down () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.25)`
      }
    },
    mounted () {

    },
    methods: {

    }
  }
</script>
